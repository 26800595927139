import { graphql } from "gatsby"
import * as React from "react"

interface Query {
  allLicensesJson: {
    edges: {
      node: {
        name: string
      }
    }[]
  }
}

// markup
const LicensePage = ({ data }: { data: Query }) => {
  return (
    <main>
      {data.allLicensesJson.edges.map((edge) => (
        <p key={edge.node.name}>{edge.node.name}</p>
      ))}
    </main>
  )
}

export const query = graphql`
  query MyQuery {
    allLicensesJson {
      edges {
        node {
          name
          
        }
      }
    }
  }
`

export default LicensePage
